import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import config from 'config/index';
import Img from 'components/Img';
import IconBack from 'images/icon_index_gotop.png';
import IconTel from 'images/icon_index_tel.png';
import IconLogo from 'images/icon_index_logo.png';
import styles from './index.module.scss';


const Index = (props) => {
    const { className = '', scrollToTop = () => { } } = props;
    const [visible, setVisible] = useState(false);
    const openSSC = () => {
        window.open(config.SSC_PC_URL)
    }
    const bodyClick = () => {
        setVisible(false)
    }
    useEffect(() => {
        document.body.addEventListener('click', bodyClick, false)
        return () => {
            document.body.removeEventListener('click', bodyClick, false)
        }
    }, [])

    const changeVisible = (e) => {
        e.stopPropagation()
        setVisible(!visible)
    }
    return <div className={classnames(styles['srcoll-top'], className)} id="SRCOLL_TOP_INDEX">
        <div className={classnames(styles['content'], styles['content-1'])} onClick={openSSC}>
            <Img src={IconLogo} />在线客服
        </div>
        <div className={styles['line']}></div>
        <div className={styles['content-relative']}>
            <div
                className={classnames(styles['content'])}
                onClick={changeVisible}>
                <Img src={IconTel} />电话沟通
            </div>
            {visible && <div className={styles['content-phone']}>
                <div className={styles['tel']}> 400-886-8523 </div>
                <span className={styles['arrow-content']}></span>
            </div>}
        </div>

        <div className={styles['line']}></div>
        <div className={classnames(styles['content'], styles['content-3'])} onClick={scrollToTop}>
            <Img src={IconBack} />
            回到顶部
        </div>
    </div>
}
export default Index;