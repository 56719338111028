import React, {
  useState,
  useMemo,
  useEffect,
  memo,
  useRef,
  useCallback,
} from "react";
import Field from "components/Field";
import { get, trim } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setHeaderApplyBtnVisible, headerBtnToggleType } from "store/global";
import isPhone, { EMAILREG, DisableEmails } from "common/isPhone";
import { AttendActivity } from "common/api/Invitation";
import corporationIcon from "images/field/corporation.svg";
import emailIcon from "images/field/email.svg";
import nameIcon from "images/field/name.svg";
import phoneIcon from "images/field/phone.svg";
import Button from "components/Button";
import cls from "classnames";
import Toast from "components/Toast";
import styles from "./index.module.scss";

const initialState = {
  userCorporation: "",
  userEmail: "",
  userName: "",
  userPhone: "",
};

const mapFieldLabel = {
  userCorporation: "企业名称",
  userEmail: "公司邮箱",
  userName: "姓名",
  userPhone: "手机号码",
};

const mapFieldError = {
  userCorporation: null,
  userEmail: null,
  userName: null,
  userPhone: null,
};

const mapFieldValidator = {
  userCorporation: null,
  userEmail: EMAILREG,
  userName: null,
  userPhone: isPhone,
};

const mapFieldIcon = {
  userCorporation: corporationIcon,
  userEmail: emailIcon,
  userName: nameIcon,
  userPhone: phoneIcon,
};

const Apply = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(mapFieldError);
  const mount = useRef(false);
  const init = useRef(false);
  useEffect(() => {
    mount.current = true;
    dispatch(
      setHeaderApplyBtnVisible({
        type: headerBtnToggleType.fromPage,
        data: false,
      })
    );
    return () => {
      mount.current = false;
    };
  }, [dispatch]);

  const checkError = useCallback(async () => {
    let isValidTag = true;
    let errorTemp = {};
    for (const [k, v] of Object.entries(data)) {
      if (!v) {
        isValidTag = false;
        errorTemp[k] = `${mapFieldLabel[k]}不能为空`;
      } else {
        const regex = mapFieldValidator[k];
        if (regex) {
          const isValid = regex.test(data[k]);
          if (!isValid) {
            isValidTag = false;
            errorTemp[k] = `${mapFieldLabel[k]}格式错误`;
          }
        }

        if (k === "userEmail") {
          const isPersonalEmail = DisableEmails.find(
            (vv) => v.indexOf(vv) > -1
          );
          if (isPersonalEmail) {
            isValidTag = false;
            errorTemp[k] = `请用公司邮箱`;
          }
        }
      }
    }
    const keys = Object.keys(errorTemp);
    // console.log(errorTemp);
    if(keys.length > 0) {
      setError(errorTemp)
    } else {
      setError(mapFieldError);
    }

    return isValidTag;
  }, [data]);

  useEffect(() => {
    if (init.current) {
      checkError();
    }
  }, [data, checkError]);

  const handleChange = (key) => (e) => {
    let val = trim(get(e, "target.value", ""));
    if (key === "userPhone") {
      val = val.replace(/\D/g, "");
    }
    setData((pre) => ({ ...pre, [key]: val }));
  };

  const handleApply = async () => {
    try {
      const isValid = await checkError();
      if (!isValid) {
        if (!init.current) {
          init.current = true;
        }
        return;
      }
      Toast.loading();
      const params = {
        ...data,
        title: "HOME_2021",
      };
      const res = await AttendActivity(params);
      const success = res?.success;
      const code = res?.data?.uniqueCode;
      const message = res?.data?.message;
      if (success && code) {
        Toast.success("提交成功");
        history.replace("/home");
        return;
      }
      throw message || "提交失败";
    } catch (e) {
      Toast.info(typeof e === "string" ? e : "提交失败，请稍后再试");
    }
  };

  const isBtnCanApply = useMemo(() => {
    for (const v of Object.values(data)) {
      if (!v) {
        return false;
      }
    }
    return true;
  }, [data]);

  return (
    <div className={styles.root}>
      <div className={styles.title}>申请体验</div>
      <div className={styles.form}>
        <Field
          value={data.userCorporation}
          onChange={handleChange("userCorporation")}
          label={mapFieldLabel.userCorporation}
          required={true}
          error={error.userCorporation}
          icon={mapFieldIcon.userCorporation}
          className={cls("fadeInFromBottom")}
        />
        <Field
          value={data.userEmail}
          onChange={handleChange("userEmail")}
          label={mapFieldLabel.userEmail}
          required={true}
          error={error.userEmail}
          icon={mapFieldIcon.userEmail}
          className={cls("fadeInFromBottom", "delay300")}
        />
        <Field
          value={data.userName}
          onChange={handleChange("userName")}
          label={mapFieldLabel.userName}
          required={true}
          error={error.userName}
          icon={mapFieldIcon.userName}
          className={cls("fadeInFromBottom", "delay400")}
        />
        <Field
          value={data.userPhone}
          onChange={handleChange("userPhone")}
          label={mapFieldLabel.userPhone}
          required={true}
          error={error.userPhone}
          icon={mapFieldIcon.userPhone}
          className={cls("fadeInFromBottom", "delay500")}
        />
        <div className={cls(styles.buttonWrap, "fadeInFromBottom", "delay600")}>
          <Button
            text="即刻申请"
            onClick={handleApply}
            className={cls(styles.commonBtn, {
              [styles.activeBtn]: isBtnCanApply,
            })}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Apply);
