import Layout from "pages/Layout";
import Home from "pages/Home";
import AI from "pages/AI";
import TRM from "pages/TRM";
import SSC from "pages/SSC";
import DEV from "pages/DEV";
import PRC from "pages/PRC";
import About from 'pages/About';
import Apply from 'pages/Apply';
import { Redirect } from "react-router-dom";

const routes = [
  {
    path: "/",
    component: Layout,
    routes: [
      {
        path: "/home",
        component: Home,
      },
      {
        path: "/ai",
        component: AI,
      },
      {
        path: "/trm",
        component: TRM,
      },
      {
        path: "/ssc",
        component: SSC,
      },
      {
        path: "/dev",
        component: DEV,
      },
      {
        path: "/prc",
        component: PRC,
      },
      {
        path: "/about",
        component: About,
      },
      {
        path: "/apply",
        component: Apply,
      },
      {
        component: () => <Redirect to='/home' />,
      },
    ],
  },
];

export default routes;
