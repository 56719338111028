import React, { useEffect, useState, useMemo, memo, useCallback } from "react";
import { RouteWithSubRoutes } from "components/Router";
import { Switch, useHistory, useLocation } from "react-router-dom";
import Logo from "images/home/logo.png";
import classnames from "classnames";
import ApplyForm from "components/ApplyForm";
import { useSelector } from "react-redux";
import { shareFn, projectPath } from "common/wxShare";
import styles from "./index.module.scss";

const MenuBtn = memo(({ show, onClick }) => {
  return (
    <div
      className={classnames(styles.menuBtn, {
        [styles.show]: show,
      })}
      onClick={onClick}
    >
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
});

const projectBaseUrl = `${window.location.origin}${projectPath}`;
const WXTag = 'from=wx';
const title = "HRTPS";
const description = "世界级HR人工智能，致力于让企业拥有世界领先的智慧管理能力";
const link = `${projectBaseUrl}?${WXTag}#/home`;
const imgUrl = `${window.location.origin}${projectPath}logo.png`;

// console.log(projectBaseUrl, link, imgUrl);

const Layout = ({ routes }) => {
  const location = useLocation();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const toggleShow = useCallback(() => setShow((pre) => !pre), []);

  useEffect(() => {
    shareFn({
      title: title,
      desc: description,
      link: link,
      imgUrl: imgUrl,
    });
  }, []);

  useEffect(() => {
    const url = window.location.href;
    if (url.includes(`?${WXTag}`)) {
      const next = `${projectBaseUrl}#/home`;
      window.location.href = next;
    }
  }, []);

  const menuClick = (path) => () => {
    history.replace(path);
    setShow(false);
  };

  const headerApplyBtnVisible = useSelector(
    (state) => state.global.headerApplyBtnVisible
  );

  const btnVisible = useMemo(() => !show && headerApplyBtnVisible, [
    show,
    headerApplyBtnVisible,
  ]);

  const headerBtnClick = useCallback(() => {
    history.replace("/apply");
    show && toggleShow();
  }, [history, show, toggleShow]);

  const handleMenuTouchMove = (e) => {
    e.preventDefault();
  };

  return (
    <div className={styles.root}>
      <div className={styles.header} id="header">
        <img
          src={Logo}
          alt="logo"
          className={styles.logo}
          onClick={menuClick("/home")}
        />
        <div className={styles.headerRight}>
          {btnVisible && (
            <ApplyForm
              display="header"
              className={styles.headerBtn}
              onClick={headerBtnClick}
            />
          )}
          <MenuBtn show={show} onClick={toggleShow} />
        </div>
      </div>
      <div className={styles.content}>
        <Switch>
          {routes.map((route, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </div>
      <div
        className={classnames(styles.menu, {
          [styles.showMenu]: show,
        })}
        onTouchMove={handleMenuTouchMove}
      >
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/home",
          })}
          onClick={menuClick("/home")}
        >
          <div>首页</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/ai",
          })}
          onClick={menuClick("/ai")}
        >
          <div>智能AI面试</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/trm",
          })}
          onClick={menuClick("/trm")}
        >
          <div>智能TRM</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/ssc",
          })}
          onClick={menuClick("/ssc")}
        >
          <div>智能SSC</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/dev",
          })}
          onClick={menuClick("/dev")}
        >
          <div>智能发展追踪</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/prc",
          })}
          onClick={menuClick("/prc")}
        >
          <div>智能陪练</div>
        </div>
        <div
          className={classnames(styles.menuItem, {
            [styles.activeMenuItem]: location.pathname === "/about",
          })}
          onClick={menuClick("/about")}
        >
          <div>关于我们</div>
        </div>
      </div>
    </div>
  );
};

export default memo(Layout);
