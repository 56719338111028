import React, { memo } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

const Index = (props) => {
  const { className = "" } = props;
  return (
    <div className={classnames(className, styles.icon)}>
    </div>
  );
};

export default memo(Index);
