const getSize = () => {
  return {
    width: window.innerWidth || document.body.clientWidth,
    // height: (window.innerHeight || document.body.clientHeight) - headerHeight(),
    height: (window.innerHeight || document.body.clientHeight),
    // height: window.screen.height,
    // height: window.screen.height - headerHeight(),
  };
};

export const headerHeight = () => {
  return (window.innerWidth / 750) * 108;
};

export default getSize;
