import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";
import ApplyForm from "components/ApplyForm";
import TitleGenerator from "components/Title";

import Page1Cover from "images/prc/home.png";

import advantage1 from "images/prc/advantage-1.png";
import advantage2 from "images/prc/advantage-2.png";
import user1 from "images/prc/1.png";
import user2 from "images/prc/2.png";
import user3 from "images/prc/3.png";
import user4 from "images/prc/4.png";
import user5 from "images/prc/5.png";
import user6 from "images/prc/6.png";



import Page4Cover from "images/prc/last-1.png";
import IconPoint from "images/point.svg";

import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [page2Index, setPage2Index] = useState(0);
  const [isLast, setIsLast] = useState(false);

  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        height={getSize().height}
        width={getSize().width}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        setWrapperSize={true}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <div
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              >
                智能陪练平台
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  随需随练的AI智能陪练平台<br />员工指尖的教练
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <img
                src={Page1Cover}
                alt="page_1_bottom"
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay700: isActive,
                })}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="两大核心优势" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage2Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={advantage1} alt="Page2Cover1" />
                  <div className={styles.horizontalSlide_title}>对企业</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>减少线下培训成本</div>
                    <div>释放培训师大量重复工作</div>
                    <div>丰富数据报表，学员学习情况全掌握</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={advantage2} alt="Page2Cover2" />
                  <div className={styles.horizontalSlide_title}>对员工</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>随时随地、随需随练，利用碎片时间学习</div>
                    <div>不受限制多次练习，练到掌握为止</div>
                    <div>与小伙伴相互学习，共同进步</div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 1,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <TitleGenerator title="适合使用人群" />
              <div className={styles.imgTopText}>岗位人数众多的基层管理者及一线员工</div>
              <div className={styles.users}>
                <div className={classnames({
                  fadeInFromBottom: isActive,
                }, styles['user-item'])}>
                  <img src={user1} alt='user' />
                  <span>基层管理者</span>
                </div>
                <div className={styles['row']}>
                  <div className={classnames({
                    fadeInFromBottom: isActive,
                    delay100: isActive,
                  }, styles['user-item'])}>
                    <img src={user2} alt='user' />
                    <span>医药代表</span>
                  </div>
                  <div className={classnames({
                    fadeInFromBottom: isActive,
                    delay200: isActive,
                  }, styles['user-item'])}>
                    <img src={user3} alt='user' />
                    <span>保险经纪</span>
                  </div>
                  <div className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  }, styles['user-item'])}>
                    <img src={user4} alt='user' />
                    <span>门店销售</span>
                  </div>
                </div>
                <div className={styles['row']}>
                  <div className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  }, styles['user-item'])}>
                    <img src={user5} alt='user' />
                    <span>快消导购</span>
                  </div>
                  <div className={classnames({
                    fadeInFromBottom: isActive,
                    delay500: isActive,
                  }, styles['user-item'])}>
                    <img src={user6} alt='user' />
                    <span>客户经理</span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <TitleGenerator title="我们的优势" />
              <img
                src={Page4Cover}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
              <div className={styles.subTitle}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    // delay600: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  随需随练
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  精准反馈
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  灵活高效
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay500: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  形式丰富
                  </div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
