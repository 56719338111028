import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";
import ApplyForm from "components/ApplyForm";
import TitleGenerator from "components/Title";

import SubTitleIcon from "components/SubTitleIcon";
import Page1Cover from "images/ai/AI-home.png";
import Page2Cover1 from "images/ai/AI-campus.png";
import Page2Cover2 from "images/ai/AI-society.png";
import Page2Cover3 from "images/ai/AI-JJFA.png";
import page3Cover from "images/ai/3illustration.png";

import Page4Cover1 from "images/ai/Strong-professional-icon.png";
import Page4Cover2 from "images/ai/Cost-reduction-icon.png";
import Page4Cover3 from "images/ai/High-flexibility-icon.png";

import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [page2Index, setPage2Index] = useState(0);
  const [page4Index, setPage4Index] = useState(0);
  const [isLast, setIsLast] = useState(false);

  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        height={getSize().height}
        width={getSize().width}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        setWrapperSize={true}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <div
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              >
                AI智能规模化招聘
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  AI技术致敬经典行为科学
                </div>
              </div>
              <div className={styles.childTitle}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  <span>
                    <SubTitleIcon />
                  </span>
                  <span>AI能力画像</span>
                </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay500: isActive,
                  })}
                >
                  <span>
                    <SubTitleIcon />
                  </span>
                  <span>AI能力解码</span>
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <img
                src={Page1Cover}
                alt="page_1_bottom"
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay700: isActive,
                })}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="保质跑量 · 招聘利器" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage2Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover1} alt="Page2Cover1" />
                  <div className={styles.horizontalSlide_title}>校园招聘</div>
                  <div className={styles.horizontalSlide_info}>
                    春招秋招、管理培训生项目、校园大使项目、暑期实习项目
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover2} alt="Page2Cover2" />
                  <div className={styles.horizontalSlide_title}>社会招聘</div>
                  <div className={styles.horizontalSlide_info}>
                    白领招聘、蓝领招聘
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover3} alt="Page2Cover3" />
                  <div className={styles.horizontalSlide_title}>
                    行业解决方案
                  </div>
                  <div className={styles.horizontalSlide_info}>
                    医药行业销售代表、快消行业店员、导购、物流行业快递员、地产经纪人等
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 1,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 2,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <img
                src={page3Cover}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="我们的优势" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage4Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page4Cover1} alt="Page4Cover1" />
                  <div className={styles.horizontalSlide_title}>专业性强</div>
                  <div className={styles.horizontalSlide_info}>
                    专业人才测评体系结合强大的AI技术
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page4Cover2} alt="Page4Cover2" />
                  <div className={styles.horizontalSlide_title}>降本增效</div>
                  <div className={styles.horizontalSlide_info}>
                    低成本、大规模，保质跑量
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page4Cover3} alt="Page4Cover3" />
                  <div className={styles.horizontalSlide_title}>灵活度高</div>
                  <div className={styles.horizontalSlide_info}>
                    根据客户不同需求提供标准或高度定制的解决方案
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page4Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page4Index === 1,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page4Index === 2,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
