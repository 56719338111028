import { useEffect } from "react";
import { WXConfig as wxConfig } from "./api/Invitation";

import sha1 from "sha1";
import crypto from "crypto";

const appId = "wxf666c96763fd982d";

const isPROD = process.env.REACT_APP_ENV === "PROD";
export const projectPath = isPROD ? "/" : "/hrtps-com-mobile/";
// console.log(process.env, isPROD, projectPath);

const getSignature = function (ticket) {
  let timestamp = parseInt(new Date().getTime() / 1000);
  let url = `${window.location.origin}${projectPath}`;
  let noncestr = crypto.randomBytes(8).toString("hex");

  let str = [
    `jsapi_ticket=${ticket}`,
    `noncestr=${noncestr}`,
    `timestamp=${timestamp}`,
    `url=${url}`,
  ]
    .sort()
    .join("&");

  let signature = sha1(str);

  return {
    signature: signature,
    appId: appId,
    timestamp: timestamp,
    nonceStr: noncestr,
  };
};

export const shareFn = ({
  title = "HRTPS",
  desc = "",
  link = "",
  imgUrl = "",
}) => {
  const isWeChat = () => {
    var ua = navigator.userAgent.toLowerCase();
    return !/wxwork/i.test(ua) && /MicroMessenger/i.test(ua);
  };
  const wx = window.wx;
  if (isWeChat() && wx) {
    wxConfig(appId)
      .then((res) => {
        const data = res?.data;
        let init = false;
        let params = {
          debug: false,
          jsApiList: ["updateAppMessageShareData", "updateTimelineShareData"],
        };
        if (typeof data === "string") {
          const para = getSignature(data);
          params = { ...params, ...para };
          init = true;
        }

        if (!init) return;

        wx?.config(params);
        wx?.ready(function () {
          wx?.updateAppMessageShareData({
            title: title,
            desc: desc,
            link: link,
            imgUrl: imgUrl,
            success: function () {
              console.log("分享给朋友及分享到QQ成功");
            },
          });
          wx?.updateTimelineShareData({
            title: title,
            link: link,
            imgUrl: imgUrl,
            success: function () {
              "分享到朋友圈及分享到QQ空间成功";
            },
          });
        });
        wx?.error(function (res) {
          console.log("wechat config error: ", res);
        });
      })
      .catch((e) => {
        console.log("eeeeee: ", e, e.response);
      });
  }
};

export default function WXConfig({
  title = "HRTPS",
  desc = "",
  link = "",
  imgUrl = "",
}) {
  useEffect(() => {
    shareFn({
      title,
      desc,
      link,
      imgUrl,
    });
  }, [title, desc, link, imgUrl]);
}
