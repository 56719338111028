import React, { useState, memo, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";

import TitleImg from "../../images/home/tittle.png";
import Page1Bottom from "../../images/home/page1_bottom.png";
import ApplyForm from "components/ApplyForm";
import AIBanner from "images/home/AI_banner.png";
import ATSBanner from "images/home/ATSbanner.png";
import SSCBanner from "images/home/SSC.png";
import DEVbanner from 'images/home/DEVbanner.png';
import PRCbanner from 'images/home/PRCbanner.png';
import CardRightArrow from "images/home/left-circle.png";
import Auth from "images/home/auth_icon.png";
import Brand from "images/home/brand_logo.png";
import customer from "images/home/customer.svg";
import bussiness from "images/home/bussiness.svg";
import address from "images/home/address.svg";
import policeLogo from "images/home/police_logo.png";

import TitleGenerator from "components/Title";
import ScrollTopIndex from 'components/ScrollTopIndex';
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [isLast, setIsLast] = useState(false);
  const swiperRef = useRef(null)

  const history = useHistory();

  const goDetail = (path) => () => {
    history.replace(path);
  };

  const scrollToTop = () => {
    if (swiperRef.current) {
      swiperRef.current.slideTo(0, 500, false);//切换到第一个slide，速度为1秒
    }
  }
  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        autoHeight={true}
        height={getSize().height}
        width={getSize().width}
        setWrapperSize={true}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper
        }}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <img
                src={TitleImg}
                alt="title_img"
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              />
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  致力于让企业拥有世界领先的
                </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  智慧管理能力
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay500: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <div
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <img src={Page1Bottom} alt="page_1_bottom" />
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="智能自动化人才供应平台" />
              <div
                className={classnames(styles.card, styles.mt80, {
                  fadeInFromBottom: isActive,
                })}
                onClick={goDetail("/ai")}
              >
                <img src={AIBanner} alt="al_banner" />
                <div className={styles.middleInfo}>
                  <div>智能AI面试</div>
                  <div>AI智能规模化招聘</div>
                </div>
                <img src={CardRightArrow} alt="card_right_arrow" />
              </div>
              <div
                className={classnames(styles.card, {
                  fadeInFromBottom: isActive,
                  delay300: isActive,
                })}
                onClick={goDetail("/trm")}
              >
                <img src={ATSBanner} alt="ats_banner" />
                <div className={styles.middleInfo}>
                  <div>智能TRM</div>
                  <div>AI智能招聘管理系统</div>
                </div>
                <img src={CardRightArrow} alt="card_right_arrow" />
              </div>
              <div
                className={classnames(styles.card, {
                  fadeInFromBottom: isActive,
                  delay400: isActive,
                })}
                onClick={goDetail("/ssc")}
              >
                <img src={SSCBanner} alt="ssc_banner" />
                <div className={styles.middleInfo}>
                  <div>智能SSC</div>
                  <div>AI智能员工服务中心</div>
                </div>
                <img src={CardRightArrow} alt="card_right_arrow" />
              </div>



              <div
                className={classnames(styles.card, {
                  fadeInFromBottom: isActive,
                  delay400: isActive,
                })}
                onClick={goDetail("/dev")}
              >
                <img src={DEVbanner} alt="ssc_banner" />
                <div className={styles.middleInfo}>
                  <div>智能发展追踪</div>
                  <div>AI智能人才管理创新实践</div>
                </div>
                <img src={CardRightArrow} alt="card_right_arrow" />
              </div>


              <div
                className={classnames(styles.card, {
                  fadeInFromBottom: isActive,
                  delay400: isActive,
                })}
                onClick={goDetail("/prc")}
              >
                <img src={PRCbanner} alt="ssc_banner" />
                <div className={styles.middleInfo}>
                  <div>智能陪练</div>
                  <div>AI智能陪练平台</div>
                </div>
                <img src={CardRightArrow} alt="card_right_arrow" />
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <img
                src={Auth}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
              <div
                className={classnames(styles.authtitle, {
                  fadeInFromBottom: isActive,
                })}
              >
                权威认证为数据安全保驾护航
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  ISO27001认证
                </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  并获得信息系统安全等级保护备案证明
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          <div className={classnames(styles.page, styles.brandpage)}>
            <TitleGenerator title="信赖我们的合作伙伴" />
            <img
              src={Brand}
              alt="brand_logo"
              className={classnames(styles.brandLogo, styles.mt144)}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.contactpage)}>
              <TitleGenerator title="联系我们" />
              <div className={classnames(styles.contactItem, styles.mt130)}>
                <img
                  src={customer}
                  alt="customer_logo"
                  className={classnames(styles.infoIcon)}
                />
                <div>客服咨询：400-886-8523</div>
                <div>周一~周五 9:00-18:00</div>
              </div>
              <div className={styles.contactItem}>
                <img
                  src={bussiness}
                  alt="customer_logo"
                  className={styles.infoIcon}
                />
                <div>商务合作：Sales@HRTPS.com</div>
              </div>
              <div className={styles.contactItem}>
                <img
                  src={address}
                  alt="customer_logo"
                  className={styles.infoIcon}
                />
                <div>公司地址：上海市徐汇区龙爱路27号5楼</div>
              </div>
              <div className={styles.copyright}>
                {/* <div>HRTPS Copyright © 2020</div>
                <div>沪ICP备15045531号沪公网安备 31010402004429号</div> */}

                <div>
                  <span>HRTPS Copyright © 2016</span>
                  <span
                    onClick={() =>
                      (window.location.href = "https://beian.miit.gov.cn/")
                    }
                  >
                    沪ICP备15045531号
                  </span>
                </div>
                <div>
                  <img src={policeLogo} alt="police-logo" />
                  <span
                    onClick={() =>
                    (window.location.href =
                      "http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010402004429")
                    }
                  >
                    沪公网安备 31010402004429号
                  </span>
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      <ScrollTopIndex className={styles['to-top']} scrollToTop={scrollToTop} />
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
