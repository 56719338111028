import React, { memo } from "react";
import Iconarrow from 'images/home/arrow.png';
import classnames from 'classnames';
import styles from "./index.module.scss";

const Index = (props) => {
    const { className = '' } = props;
    return (
        <div className={classnames(className, styles['icon-up-arrow'])}>
            <img className={styles['animate-bounce-down']} alt="向上滑动" src={Iconarrow} />
        </div>
    );
};

export default memo(Index);
