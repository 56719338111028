import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";
import ApplyForm from "components/ApplyForm";
import TitleGenerator from "components/Title";

import Page1Cover from "images/ssc/ssc-home.png";

import Page2Cover1 from "images/ssc/ssc-zdh.png";
import Page2Cover2 from "images/ssc/ssc-chatbot.png";
import Page2Cover3 from "images/ssc/ssc-qyzsk.png";

import Page3Cover1 from "images/ssc/ssc-hxln-shengxin.png";
import Page3Cover2 from "images/ssc/ssc-hxln-shengshi.png";
import Page3Cover3 from "images/ssc/ssc-hxln-shengli.png";

import Page4Cover from "images/ssc/ssc-wmdys.png";

import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [page2Index, setPage2Index] = useState(0);
  const [page3Index, setPage3Index] = useState(0);
  const [isLast, setIsLast] = useState(false);

  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        height={getSize().height}
        width={getSize().width}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        setWrapperSize={true}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <div
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              >
                AI智能员工服务中心
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  打造一键体验，提供一站式自动化职能服务，升级员工体验
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <img
                src={Page1Cover}
                alt="page_1_bottom"
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay700: isActive,
                })}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="共享服务中心解决方案" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage2Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover1} alt="Page2Cover1" />
                  <div className={styles.horizontalSlide_title}>
                    自动化审批流
                  </div>
                  <div className={styles.horizontalSlide_info}>
                    <div>入转调离</div>
                    <div>工作台</div>
                    <div>入职绩效考核</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover2} alt="Page2Cover2" />
                  <div className={styles.horizontalSlide_title}>ChatBot</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>相似度</div>
                    <div>情感分析</div>
                    <div>算法解析</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page2Cover3} alt="Page2Cover3" />
                  <div className={styles.horizontalSlide_title}>企业知识库</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>专注于企业</div>
                    <div>海量知识库</div>
                    <div>根据企业定制</div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 1,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 2,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="核心理念" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage3Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page3Cover1} alt="Page3Cover1" />
                  <div className={styles.horizontalSlide_title}>更省心</div>
                  <div className={styles.horizontalSlide_info}>
                    作为人力资本数字化平台，并专注于人力资源领域知识打造。
                    {/* <div>入转调离</div>
                    <div>工作台</div>
                    <div>入职绩效考核</div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page3Cover2} alt="Page3Cover2" />
                  <div className={styles.horizontalSlide_title}>更省时</div>
                  <div className={styles.horizontalSlide_info}>
                    以自然语言理解及深度学习技术为核心，通过多轮对话，上下文理解，命名实体识别等技术，以标准问答作为基础，经由对话分析后，产品都能更精准抓取用户意图。
                    {/* <div>相似度</div>
                    <div>情感分析</div>
                    <div>算法解析</div> */}
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={Page3Cover3} alt="Page3Cover3" />
                  <div className={styles.horizontalSlide_title}>更省力</div>
                  <div className={styles.horizontalSlide_info}>
                    专业运维团队来负责知识库的搭建和完善，训练师团队会不断拓展知识库的问答，确保员工各种会问到的问题被覆盖。
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page3Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page3Index === 1,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page3Index === 2,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <TitleGenerator title="我们的优势" />
              <img
                src={Page4Cover}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
              <div className={styles.subTitle}>
                <div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      // delay600: isActive,
                    })}
                  >
                    AI算法支持
                  </div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay300: isActive,
                    })}
                  >
                    语义的精准理解
                  </div>
                </div>
                <div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay400: isActive,
                    })}
                  >
                    强大的知识库管理
                  </div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay500: isActive,
                    })}
                  >
                    丰富语料库，即插即用
                  </div>
                </div>
                <div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay600: isActive,
                    })}
                  >
                    安全的部署方式
                  </div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay700: isActive,
                    })}
                  >
                    本地部署 + 云算法
                  </div>
                </div>
                <div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay800: isActive,
                    })}
                  >
                    运维团队持续支持
                  </div>
                  <div
                    className={classnames({
                      fadeInFromBottom: isActive,
                      delay900: isActive,
                    })}
                  >
                    对话系统持续迭代优化
                  </div>
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
