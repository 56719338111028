import { createSlice } from "@reduxjs/toolkit";

export const headerBtnToggleType = {
  fromPage: "fromPage",
  fromHeader: "fromHeader",
};

const globalSlice = createSlice({
  name: "global",
  initialState: {
    headerApplyBtnVisible: false,
  },
  reducers: {
    setHeaderApplyBtnVisible: (state, { payload }) => {
        state.headerApplyBtnVisible = payload.data;
    },
  },
});

export const mapPageIndexToHeaderBtnVisible = (index) => (dispatch) => {
  dispatch(
    setHeaderApplyBtnVisible({
      type: headerBtnToggleType.fromPage,
      data: index !== 0,
    })
  );
};

export const { setHeaderApplyBtnVisible } = globalSlice.actions;

export default globalSlice.reducer;
