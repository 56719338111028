import axios from 'axios';
import config from 'config/index';

// console.log(process.env.REACT_APP_ENV);

const requestURL = process.env.REACT_APP_ENV ? (config.API_URL || '/') : '/';

const axiosInstance = axios.create({
  baseURL: requestURL,
  timeout: 300000,
});
axiosInstance.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    return config;
  },
  error => {
    // 对请求错误做些什么
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  response => {
    // 对响应数据做点什么
    return response.data;
  },
  error => {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);


class Axois {
  request(config) {
    return axiosInstance.request(config);
  }
  get(url, data = {}) {
    return this.request({
      method: 'GET',
      url: url,
      params: data
    });
  }
  post(url, data = {}) {
    return this.request({
      method: 'POST',
      url: url,
      data: data
    });
  }
  delete(url, data = {}) {
    return this.request({
      method: 'DELETE',
      url: url,
      params: data
    });
  }
  put(url, data = {}) {
    return this.request({
      method: 'PUT',
      url: url,
      data: data
    });
  }
}

export default new Axois();