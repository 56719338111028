import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";
import ApplyForm from "components/ApplyForm";
import TitleGenerator from "components/Title";
import SubTitleIcon from "components/SubTitleIcon";

import Page1Cover from "images/trm/trm-home.png";

import Page2Icon1 from "images/trm/trm-yjfb.png";
import Page2Icon2 from "images/trm/trm-jlpp.png";
import Page2Icon3 from "images/trm/trm-spms.png";
import Page2Icon4 from "images/trm/trm-sjzx.png";
import Page2Icon5 from "images/trm/trm-wxzp.png";
import Page2Icon6 from "images/trm/trm-fwpt.png";

import page3Cover from "images/trm/trm-youshi.png";

import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [isLast, setIsLast] = useState(false);

  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        height={getSize().height}
        width={getSize().width}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        setWrapperSize={true}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <div
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              >
                AI智能招聘管理系统
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  从职位发布到候选人入职，提供全流程智能招聘管理解决方案，帮助HR轻松提升效能
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <img
                src={Page1Cover}
                alt="page_1_bottom"
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay700: isActive,
                })}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="智能招聘为HR赋能" />
              <div className={classnames(styles.itemContainer)}>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                  })}
                >
                  <img src={Page2Icon1} alt="Page2Icon1" />
                  <div className={styles.itemRight}>
                    <div>一键发布</div>
                    <div>多平台职位一键发布</div>
                  </div>
                </div>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  <img src={Page2Icon2} alt="Page2Icon2" />
                  <div className={styles.itemRight}>
                    <div>智能简历匹配</div>
                    <div>职位、简历双重分析，科学匹配，精准打分</div>
                  </div>
                </div>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  <img src={Page2Icon3} alt="Page2Icon3" />
                  <div className={styles.itemRight}>
                    <div>在线面试</div>
                    <div>不限距离，无需下载</div>
                  </div>
                </div>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                    delay500: isActive,
                  })}
                >
                  <img src={Page2Icon4} alt="Page2Icon4" />
                  <div className={styles.itemRight}>
                    <div>数据中心</div>
                    <div>建立各维度招聘数据统计，招聘效果深度总结</div>
                  </div>
                </div>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                    delay600: isActive,
                  })}
                >
                  <img src={Page2Icon5} alt="Page2Icon5" />
                  <div className={styles.itemRight}>
                    <div>微信招聘</div>
                    <div>私域流量高效利用，人脉力量最大化</div>
                  </div>
                </div>
                <div
                  className={classnames(styles.page2Item, {
                    fadeInFromBottom: isActive,
                    delay700: isActive,
                  })}
                >
                  <img src={Page2Icon6} alt="Page2Icon6" />
                  <div className={styles.itemRight}>
                    <div>无缝对接员工服务平台</div>
                    <div>招聘入职无缝衔接，无需信息二次输入</div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <TitleGenerator title="我们的优势" />
              <img
                src={page3Cover}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
              <div
                className={classnames(styles.advantage, {
                  fadeInFromBottom: isActive,
                })}
              >
                <div>
                  <SubTitleIcon />
                </div>
                <div>简洁高效</div>
              </div>
              <div
                className={classnames(styles.advantage, {
                  fadeInFromBottom: isActive,
                  delay300: isActive,
                })}
              >
                <div>
                  <SubTitleIcon />
                </div>
                <div>丰富数据可视化</div>
              </div>
              <div
                className={classnames(styles.advantage, {
                  fadeInFromBottom: isActive,
                  delay400: isActive,
                })}
              >
                <div>
                  <SubTitleIcon />
                </div>
                <div>流程自动化</div>
              </div>
              <div
                className={classnames(styles.advantage, {
                  fadeInFromBottom: isActive,
                  delay500: isActive,
                })}
              >
                <div>
                  <SubTitleIcon />
                </div>
                <div>灵活稳定</div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
