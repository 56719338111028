const ENV = {
  DEV: 'DEV',
  TEST: 'TEST',
  PROD: 'PROD'
};
const envMapConfig = {
  [ENV.DEV]: {
    API_URL: "https://dev.project.hrtps.com",
    SSC_PC_URL: 'https://dev.ssc.hrtps.com/chatbot/#/tourist/1'
  },
  [ENV.TEST]: {
    API_URL: "https://test.project.hrtps.com",
    SSC_PC_URL: 'https://test.ssc.hrtps.com/chatbot/#/tourist/1'
  },
  [ENV.PROD]: {
    API_URL: "https://project.hrtps.com",
    SSC_PC_URL: 'https://ssc.hrtps.com/chatbot/#/tourist/2'
  },
};

const REACT_APP_ENV = process.env.REACT_APP_ENV || ENV.DEV;
console.log(' process.env.REACT_APP_ENV==', process.env.REACT_APP_ENV)

const getConfig = () => {
  return envMapConfig[REACT_APP_ENV];
};

export default getConfig();