import React from "react";
import TitleIcon from "components/TitleIcon";
import styles from './index.module.scss';

const TitleGenerator = ({ title = "" }) => {
  return (
    <div className={styles.infoTitle}>
      <div className={styles.titleIconWrap}>
        <TitleIcon />
      </div>
      <div className={styles.page2Title}>{title}</div>
    </div>
  );
};

export default TitleGenerator;
