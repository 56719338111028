import { useEffect } from "react";

export default function DisableDragPage() {
  const preventDefault = (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    window.addEventListener("touchmove", preventDefault, { passive: false });
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: false,
      });
    };
  }, []);
}
