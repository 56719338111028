import React, { useEffect, memo } from "react";
import { useDispatch } from "react-redux";
import { setHeaderApplyBtnVisible, headerBtnToggleType } from "store/global";
import TitleGenerator from "components/Title";
import cs from "classnames";
import styles from "./index.module.scss";

const wrapperClass = "hrtps-mobile-com-about-page-wrapper";
const contentClass = "hrtps-mobile-com-about-page-content";

const About = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      setHeaderApplyBtnVisible({
        type: headerBtnToggleType.fromPage,
        data: true,
      })
    );
  }, [dispatch]);

  return (
    <div className={cs(styles.root, wrapperClass)}>
      <div className={cs(styles.content, contentClass)}>
        <TitleGenerator title="关于我们" />
        <div className={cs(styles.paragraph)}>
          <span>
            HR Total Premium Solutions (HRTPS®)
            ，上海才历网络有限公司，研发并利用强大的人工智能（AI）技术，为人力资本开发应用并且提供解决方案，在VUCA时代不断发现，分析并且解决人力资源的挑战。
          </span>
          <span>
            人工智能（AI）赋能人力资源专业人士，追踪人才领导力发展，管理变革，推进企业文化和价值观，进行绩效管理等。
          </span>
          <span>
            公司汇聚了来自互联网行业的人工智能（AI）高端一流人才，同时也有从事跨国企业人力资源十余年的人力资源专家和组织行为学家，精英的海外人才团队更是为企业注入了全新的发展动力，在企业级专属服务领域独树一帜。
          </span>
        </div>
        <div className={cs(styles.paragraph)}>
          HRTPS®通过优质服务，强大的技术能力和创新的人工智能（AI）解决方案，成为企业客户的专注合作伙伴。
        </div>
        <div className={cs(styles.paragraph)}>
          <span>
            HR Total Premium Solutions (HRTPS®) builds applications and
            solutions that enable Human Resources with powerful Artificial
            Intelligence (AI) technologies to discover, analyze and tackle the
            HR challenges in the VUCA era.
          </span>
          <span>
            The AI empowers HR to track Talent and Leadership Development, make
            Change Management and Transformation, advance Corporate Culture and
            Value and run Performance Management etc.
          </span>
          <span>
            HRTPS® gleans top AI R&D talents in the internet industry and
            accomplished HR/Organizational Behavior experts with solid
            experience in global multi-national corporations- a key
            distinguishing factor in the Enterprise Service industry.
          </span>
        </div>
        <div className={cs(styles.paragraph)}>
          HRTPS® is committed to being a dedicated partner to enterprise clients
          with our premium services, strong technical capabilities and
          innovative AI solutions.
        </div>
      </div>
    </div>
  );
};

export default memo(About);
