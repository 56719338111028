import React, { memo } from "react";
import classnames from "classnames";
import styles from "./index.module.scss";

const Field = ({
  value,
  onChange,
  label,
  className,
  required = false,
  placeholder = "",
  style = {},
  disable = false,
  onFocus = () => {},
  onBlur = () => {},
  error = null,
  icon = null,
}) => {

  return (
    <div className={`${styles.root} ${className || ""}`} style={style}>
      <label
        className={classnames({
          [styles.labelRequired]: required,
        })}
      >
        {icon && <img src={icon} alt="field-cion" />}
        <span>{label}</span>
        {required && <span className={styles.required}>*</span>}
      </label>
      <input
        placeholder={placeholder}
        type="text"
        value={value}
        onChange={onChange}
        disable={disable ? "disabled" : ""}
        onFocus={onFocus}
        onBlur={onBlur}
        className={classnames({
          [styles.errorInput]: typeof error === "string" && error,
        })}
      />
      {typeof error === "string" && error && (
        <div className={styles.error}>{error}</div>
      )}
    </div>
  );
};

export default memo(Field);
