import React, { useState, memo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import DisableDragPage from "common/DisableDragPage";
import BottomArrow from "components/BottomArrow";
import ApplyForm from "components/ApplyForm";
import TitleGenerator from "components/Title";

import Page1Cover from "images/dev/home.png";

import sence1 from "images/dev/sence-1.png";
import sence2 from "images/dev/sence-2.png";
import sence3 from "images/dev/sence-3.png";
import way1 from "images/dev/way-1.png";
import way2 from "images/dev/way-2.png";

import Page4Cover from "images/dev/ad-1.png";
import IconPoint from "images/point.svg";

import { useDispatch } from "react-redux";
import { mapPageIndexToHeaderBtnVisible } from "store/global";
import getSize from "common/swiperSize";

import classnames from "classnames";
import styles from "./index.module.scss";

const DinnerInvitation = () => {
  DisableDragPage();
  const dispatch = useDispatch();
  const [page2Index, setPage2Index] = useState(0);
  const [page3Index, setPage3Index] = useState(0);
  const [isLast, setIsLast] = useState(false);

  return (
    <div className={styles.root}>
      <Swiper
        slidesPerView={1}
        speed={500}
        initialSlide={0}
        touchRatio={2}
        direction={"vertical"}
        grabCursor={true}
        height={getSize().height}
        width={getSize().width}
        onInit={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
        }}
        onSlideChange={(swiper) => {
          dispatch(mapPageIndexToHeaderBtnVisible(swiper.activeIndex));
          setIsLast(swiper.isEnd);
        }}
        setWrapperSize={true}
        updateOnWindowResize={true}
      >
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.homepage)}>
              <div
                className={classnames(styles.titleImg, {
                  fadeInFromBottom: isActive,
                })}
              >
                智能发展追踪
              </div>
              <div className={classnames(styles.subTitle)}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  慧眼识才 助推发展<br />
                  AI时代企业人才管理创新实践
                </div>
              </div>
              <div
                className={classnames(styles.applyButton, {
                  fadeInFromBottom: isActive,
                  delay600: isActive,
                })}
              >
                <ApplyForm />
              </div>
              <img
                src={Page1Cover}
                alt="page_1_bottom"
                className={classnames(styles.page1Bottom, {
                  fadeInFromBottom: isActive,
                  delay700: isActive,
                })}
              />
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="助力多场景人才发展" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage2Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={sence1} alt="Page2Cover1" />
                  <div className={styles.horizontalSlide_title}>
                    评价追踪能力变化
                  </div>
                  <div className={styles.horizontalSlide_info}>
                    <div>关键岗位能力提升</div>
                    <div>继任者加速发展</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={sence2} alt="Page2Cover2" />
                  <div className={styles.horizontalSlide_title}>辅助管理流程实施</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>绩效管理辅助</div>
                    <div>人才盘点辅助</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={sence3} alt="Page2Cover3" />
                  <div className={styles.horizontalSlide_title}>助推文化落地进程</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>企业文化落地工具</div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 1,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page2Index === 2,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>

        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.infopage)}>
              <TitleGenerator title="AI技术+经典方法论" />
              <Swiper
                slidesPerView={1}
                speed={400}
                initialSlide={0}
                touchRatio={2}
                grabCursor={true}
                height={getSize().height}
                width={getSize().width}
                onSlideChange={(swiper) => {
                  setPage3Index(swiper.activeIndex);
                }}
                setWrapperSize={true}
              >
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={way1} alt="Page3Cover1" />
                  <div className={styles.horizontalSlide_title}>行为事件访谈（BEI）</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>衡量行为   贴近实际</div>
                    <div>客观评价   信效度高</div>
                  </div>
                </SwiperSlide>
                <SwiperSlide className={styles.horizontalSlide}>
                  <img src={way2} alt="Page3Cover2" />
                  <div className={styles.horizontalSlide_title}>AI自然语言理解（NLU）</div>
                  <div className={styles.horizontalSlide_info}>
                    <div>规模测量   行为解码</div>
                    <div>动态评估   轻量高效</div>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className={styles.customerDots}>
                <span
                  className={classnames({
                    [styles.dotActive]: page3Index === 0,
                  })}
                ></span>
                <span
                  className={classnames({
                    [styles.dotActive]: page3Index === 1,
                  })}
                ></span>
              </div>
            </div>
          )}
        </SwiperSlide>
        <SwiperSlide className={styles.slide}>
          {({ isActive }) => (
            <div className={classnames(styles.page, styles.authpage)}>
              <TitleGenerator title="我们的优势" />
              <div className={styles.imgTopText}>不仅有单次测评的能力快照，而且通过持续追踪形成立体的能力录像——更立体，更客观</div>

              <img
                src={Page4Cover}
                alt="auth_background_img"
                className={classnames(styles.authBg)}
              />
              <div className={styles.subTitle}>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    // delay600: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                    高频多次
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay300: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                    轻便快捷
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay400: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  方法经典
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay500: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  性价比高
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay600: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  技术领先
                  </div>
                <div
                  className={classnames({
                    fadeInFromBottom: isActive,
                    delay700: isActive,
                  })}
                >
                  <img src={IconPoint} alt="IconPoint" />
                  功能强大
                  </div>
              </div>
            </div>
          )}
        </SwiperSlide>
      </Swiper>
      {!isLast && <BottomArrow className={styles["icon-arrow"]} />}
    </div>
  );
};

export default memo(DinnerInvitation);
