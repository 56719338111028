import React, { memo } from "react";
import classnames from 'classnames';
import styles from "./index.module.scss";

const Button = ({ text, onClick, style = {}, disable = false, className = "" }) => {
  return (
    <button className={classnames(styles.root, className)} onClick={onClick} style={style} disable={disable ? 'disabled' : ''}>
      {text}
    </button>
  );
};

export default memo(Button);
