import React from "react";
import classnames from "classnames";
import { useHistory } from "react-router-dom";
import styles from "./index.module.scss";

export default function ApplyForm({ display = "primary", className }) {
  const history = useHistory();

  const handleClick = () => {
    history.replace("/apply");
  };

  return (
    <div className={classnames(styles.root, className)} onClick={handleClick}>
      {display === "primary" && (
        <div className={classnames(styles.displayButton)}>申请体验</div>
      )}
      {display === "header" && (
        <div className={classnames(styles.displayButtonHeater)}>申请体验</div>
      )}
    </div>
  );
}
